import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import styles from './DefineCampaign.module.scss'
import {
  TextInput,
  TextArea,
  PrimaryButton,
  SecondaryButton,
  DateRangePicker,
  AmbassadorReward,
  ProspectReward,
  LoadingOverlay,
  HelpModal,
} from 'components'
import {
  REWARD__AR__ITEM,
  REWARD__PR__ITEM,
  CAMPAIGN_STATUS,
  CAMPAIGN_STEP,
  REWARD_TYPE,
} from 'utils/constants'
import _ from 'lodash'
import * as Yup from 'yup'
import { useStateValue } from 'state'
import { getFormikCaptionTextData } from 'utils'
import { toast } from 'react-toastify'

const DefineCampaign = ({
  isLocked = false,
  step: initStep,
  onBack = () => null,
  onNext = () => null,
  onSave = () => null,
  data: initData = {},
  activeCampagin,
  campaign,
  setDirty = () => null,
  saveAndClose = () => null,
}) => {
  const campaignRef = useRef(null)
  const ambassadorRef = useRef(null)
  const prospectRef = useRef(null)
  const {
    action: { updateCampaign, setCampaignRewards },
    state: {
      camp: { activeCamp },
      help,
    },
  } = useStateValue()

  const [isSubmitted, setSubmitted] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isNext, setNext] = useState(false)
  const [isStayHere, setIsStayHere] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [areRewardsDirty, setAreRewardsDirty] = useState(false)

  useEffect(() => {
    setDirty(isDirty || areRewardsDirty)
  }, [isDirty, areRewardsDirty])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      goal: '',
      definition: '',
      // season: initData.season,
      startDate: new Date().toString(),
      endDate: new Date().toString(),
      status: CAMPAIGN_STATUS.INCOMPLETE,
      ambassadorRewards: initData.AmbassadorRewards || [REWARD__AR__ITEM],
      prospectRewards: initData.ProspectRewards || [REWARD__PR__ITEM],
      ..._.omit(initData, [
        /* 'season', */ 'ambassadorRewards',
        'prospectRewards',
      ]),
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Campaign Name is required.'),
      goal: Yup.string()
        .required('Campaign Goal is required.')
        .max(50, 'Max length is 50.'),
      definition: Yup.string()
        .required('Campaign Description is required.')
        .max(200, 'Max length is 200.'),
      startDate: Yup.string().required('Campagin Period is required'),
      endDate: Yup.string().required('Campagin Period is required'),
      ambassadorRewards: Yup.array()
        .of(
          Yup.object({
            desc: Yup.string()
              .required('Description is required.')
              .max(100, 'Max length is 100.'),
            amount: Yup.number()
              .required('Amount is required.')
              .moreThan(0, 'Must be greater than 0.'),
          })
        )
        .required(),
      prospectRewards: Yup.array()
        .of(
          Yup.object({
            desc: Yup.string()
              .required('Description is required.')
              .max(100, 'Max length is 100.'),
            amount: Yup.number()
              .required('Amount is required.')
              .moreThan(0, 'Must be greater than 0.'),
            promocode: Yup.string()
              .required('Promo Code is required.')
              .max(10, 'Max length is 10.'),
          })
        )
        .required(),
    }),
    onSubmit: ({
      id,
      name,
      goal,
      definition,
      /* season, */ startDate,
      endDate,
      ambassadorRewards,
      prospectRewards,
    }) => {
      // if (isLocked) return isNext ? onNext() : navigate({ pathname: PATH.CAMPAIGNS });
      setLoading(true)

      const data = {
        name,
        goal,
        definition /* season, */,
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate).toISOString(),
      }

      const handleSetCampaignRewards = (campaign) =>
        setCampaignRewards(
          campaign.id,
          {
            [REWARD_TYPE.AMBASSADOR]: ambassadorRewards,
            [REWARD_TYPE.PROSPECT]: prospectRewards,
          },
          ({
            [REWARD_TYPE.AMBASSADOR]: ambassadorRewards,
            [REWARD_TYPE.PROSPECT]: prospectRewards,
          }) => {
            setLoading(false)

            if (isNext)
              onNext({
                ...campaign,
                AmbassadorRewards: ambassadorRewards,
                ProspectRewards: prospectRewards,
              })
            else if (isStayHere)
              onSave({
                ...campaign,
                AmbassadorRewards: ambassadorRewards,
                ProspectRewards: prospectRewards,
              })
            else saveAndClose()
          }
        )

      if (isDirty)
        updateCampaign(id, data, (campaign) => {
          if (areRewardsDirty) handleSetCampaignRewards(campaign)
          else {
            setLoading(false)

            if (isNext)
              onNext({
                ...campaign,
                AmbassadorRewards: ambassadorRewards,
                ProspectRewards: prospectRewards,
              })
            else if (isStayHere)
              onSave({
                ...campaign,
                AmbassadorRewards: ambassadorRewards,
                ProspectRewards: prospectRewards,
              })
            else saveAndClose()
          }
        })
      else if (areRewardsDirty) {
        handleSetCampaignRewards(campaign)
      } else {
        setLoading(false)
        if (isNext)
          onNext({
            ...campaign,
            AmbassadorRewards: ambassadorRewards,
            ProspectRewards: prospectRewards,
          })
        else if (isStayHere)
          onSave({
            ...campaign,
            AmbassadorRewards: ambassadorRewards,
            ProspectRewards: prospectRewards,
          })
        else saveAndClose()
      }
    },
  })

  useEffect(() => {
    const {
      name,
      goal,
      definition,
      startDate,
      endDate,
      ambassadorRewards,
      prospectRewards,
    } = formik.values
    if (initData) {
      setIsDirty(
        !_.isEqual(
          {
            name,
            goal,
            definition,
            startDate,
            endDate,
          },
          {
            name: initData.name,
            goal: initData.goal,
            definition: initData.definition,
            startDate:
              initData?.startDate && new Date(initData.startDate).toISOString(),
            endDate:
              initData?.startDate && new Date(initData.endDate).toISOString(),
          }
        )
      )
      setAreRewardsDirty(
        !_.isEqualWith(
          _.map(initData.AmbassadorRewards, function (reward) {
            return _.omit(reward, [
              'campaignId',
              'created_at',
              'deleted_at',
              'templateVars',
              'updated_at',
            ])
          }),
          _.map(ambassadorRewards, function (reward) {
            return _.omit(reward, [
              'campaignId',
              'created_at',
              'deleted_at',
              'templateVars',
              'updated_at',
            ])
          }),
          (value1, value2) => (value1 == value2 ? true : undefined)
        ) ||
          !_.isEqualWith(
            _.map(initData.ProspectRewards, function (reward) {
              return _.omit(reward, [
                'campaignId',
                'created_at',
                'deleted_at',
                'templateVars',
                'updated_at',
              ])
            }),
            _.map(prospectRewards, function (reward) {
              return _.omit(reward, [
                'campaignId',
                'created_at',
                'deleted_at',
                'templateVars',
                'updated_at',
              ])
            }),
            (value1, value2) => (value1 == value2 ? true : undefined)
          )
      )
    }
  }, [formik, initData])

  useEffect(() => {
    if (initStep === CAMPAIGN_STEP.DEFINE__AMBASSADOR)
      ambassadorRef.current.scrollIntoView({ behavior: 'smooth' })
    else if (initStep === CAMPAIGN_STEP.DEFINE__PROSPECT)
      prospectRef.current.scrollIntoView({ behavior: 'smooth' })
    else window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [initStep])

  useEffect(() => {
    if (!isSubmitted) return

    if (_.isEmpty(formik.touched) || _.isEmpty(formik.errors)) return

    const toastErrors = (errors) =>
      _.values(errors).forEach((error) => {
        if (typeof error === 'string') toast.error(error)
        else toastErrors(error)
      })

    toastErrors(formik.errors)

    setSubmitted(false)

    // eslint-disable-next-line
  }, [isSubmitted])

  const handleClickSubmit = (isNext) => {
    setNext(isNext)
    setIsStayHere(false)
    setSubmitted(true)
    formik.handleSubmit()
  }

  const handleSubmitOnly = () => {
    setIsStayHere(true)
    setSubmitted(true)
    formik.handleSubmit()
  }

  return (
    <>
      <div className={styles.section}>
        <form className={styles.form}>
          <fieldset ref={campaignRef} className={styles.section__campaign}>
            <div className={styles.profile_container}>
              <h4 className={styles.section__title}>Campaign Name & Dates</h4>
              {help.find(
                (h) => h.tag === 'define_campaign_name_and_dates_overview'
              ) && (
                <HelpModal
                  className={styles.helpModal}
                  help={help.find(
                    (h) => h.tag === 'define_campaign_name_and_dates_overview'
                  )}
                >
                  <p>
                    {
                      help.find(
                        (h) =>
                          h.tag === 'define_campaign_name_and_dates_overview'
                      )?.text
                    }
                  </p>
                </HelpModal>
              )}
            </div>
            <div className={styles.form__row}>
              <div className={styles.form__col}>
                <TextInput
                  label="Campaign Title"
                  value={formik.values.name}
                  onChange={(value) => formik.setFieldValue('name', value)}
                  disabled={activeCampagin ? false : isLocked}
                  {...(formik.touched.name && formik.errors.name
                    ? { isError: true, captionText: formik.errors.name }
                    : {})}
                  help={help.find(
                    (h) => h.tag === 'define_campaign_name_and_dates_title'
                  )}
                  helpChildren={
                    <p>
                      {
                        help.find(
                          (h) =>
                            h.tag === 'define_campaign_name_and_dates_title'
                        )?.text
                      }
                    </p>
                  }
                />

                <TextInput
                  label="Campaign Goal"
                  value={formik.values.goal}
                  onChange={(value) => formik.setFieldValue('goal', value)}
                  disabled={activeCampagin ? false : isLocked}
                  {...(formik.touched.goal && formik.errors.goal
                    ? { isError: true, captionText: formik.errors.goal }
                    : {})}
                  {...getFormikCaptionTextData(formik, 'goal', {
                    captionText: `${formik.values.goal.length}/50`,
                  })}
                  help={help.find(
                    (h) => h.tag === 'define_campaign_name_and_dates_goal'
                  )}
                  helpChildren={
                    <p>
                      {
                        help.find(
                          (h) => h.tag === 'define_campaign_name_and_dates_goal'
                        )?.text
                      }
                    </p>
                  }
                />
              </div>

              <div className={styles.form__col}>
                <TextArea
                  classNames={{
                    textarea: styles.form__field__campaign__definition,
                  }}
                  label="Define Campaign"
                  placeholder="Describe the details of this campaign."
                  value={formik.values.definition}
                  onChange={(value) =>
                    formik.setFieldValue('definition', value)
                  }
                  disabled={activeCampagin ? false : isLocked}
                  {...(formik.touched.definition && formik.errors.definition
                    ? { isError: true, captionText: formik.errors.definition }
                    : {})}
                  {...getFormikCaptionTextData(formik, 'definition', {
                    captionText: `${formik.values.definition.length}/200`,
                  })}
                  help={help.find(
                    (h) => h.tag === 'define_campaign_name_and_dates_desc'
                  )}
                  helpChildren={
                    <p>
                      {
                        help.find(
                          (h) => h.tag === 'define_campaign_name_and_dates_desc'
                        )?.text
                      }
                    </p>
                  }
                />
              </div>
            </div>

            <div className={styles.form__row}>
              {/* <div className={clsx(styles.form__col, styles.form__col__camp_season)}>
                <Select
                  label='Camp Season'
                  disabled={isLocked}
                  value={formik.values.season}
                  options={seasonOptions}
                  onChange={season => formik.setFieldValue('season', season)}
                />

                <div className={styles.button__camp_season__wrapper}>
                  <label>Edit</label>
                  <PrimaryButton
                    label='Edit'
                    disabled={isLocked}
                    onClick={() => setShowSeasonModal(true)}
                  />
                </div>
              </div> */}
              <div className={styles.form__col}>
                <DateRangePicker
                  label="Campaign Period"
                  disabled={activeCampagin ? false : isLocked}
                  startDate={formik.values.startDate}
                  endDate={formik.values.endDate}
                  activeCampagin={activeCampagin}
                  onChange={({ startDate, endDate }) => {
                    formik.setFieldValue('startDate', startDate)
                    formik.setFieldValue('endDate', endDate)
                  }}
                  {...(formik.touched.startDate && formik.errors.startDate
                    ? { isError: true, captionText: formik.errors.startDate }
                    : {})}
                  {...(formik.touched.endDate && formik.errors.endDate
                    ? { isError: true, captionText: formik.errors.endDate }
                    : {})}
                  help={help.find(
                    (h) => h.tag === 'define_campaign_name_and_dates_period'
                  )}
                  helpChildren={
                    <p>
                      {
                        help.find(
                          (h) =>
                            h.tag === 'define_campaign_name_and_dates_period'
                        )?.text
                      }
                    </p>
                  }
                />
              </div>

              <div className={styles.form__col} />
            </div>
          </fieldset>

          <fieldset
            ref={ambassadorRef}
            className={styles.section__ambassador_rewards}
          >
            <div className={styles.profile_container}>
              <h4 className={styles.section__title}>Ambassador Rewards</h4>
              {help.find(
                (h) => h.tag === 'define_campaign_ambassador_rewards'
              ) && (
                <HelpModal
                  className={styles.helpModal}
                  help={help.find(
                    (h) => h.tag === 'define_campaign_ambassador_rewards'
                  )}
                >
                  <p>
                    {
                      help.find(
                        (h) => h.tag === 'define_campaign_ambassador_rewards'
                      )?.text
                    }
                  </p>
                </HelpModal>
              )}
            </div>

            {formik.values.ambassadorRewards.map((reward, rIndex) => (
              <AmbassadorReward
                className={styles.ambassador_reward}
                camp={activeCamp}
                disabled={activeCampagin ? false : isLocked}
                reward={reward}
                rIndex={rIndex}
                error={
                  formik.touched.ambassadorRewards &&
                  formik.touched.ambassadorRewards[rIndex] &&
                  formik.errors.ambassadorRewards
                    ? formik.errors.ambassadorRewards[rIndex]
                    : {}
                }
                onClose={() =>
                  formik.setFieldValue(
                    'ambassadorRewards',
                    _.difference(formik.values.ambassadorRewards, [reward])
                  )
                }
                onChange={(reward) =>
                  formik.setFieldValue(
                    'ambassadorRewards',
                    formik.values.ambassadorRewards.map((_reward, index) =>
                      index === rIndex ? reward : _reward
                    )
                  )
                }
                key={rIndex}
                formik={formik}
              />
            ))}

            {/* {!isLocked && (
              <div className={styles.button__add_reward__wrapper}>
                <PrimaryButton
                  className={styles.button__add_reward}
                  onClick={() => formik.setFieldValue('ambassadorRewards', _.concat(formik.values.ambassadorRewards, REWARD__AR__ITEM))}
                >
                  <PlusCircleIcon className={styles.button__add_reward__icon} /> Add Reward
                </PrimaryButton>
              </div>
            )} */}
          </fieldset>

          <fieldset
            ref={prospectRef}
            className={styles.section__prospect_rewards}
          >
            <div className={styles.profile_container}>
              <h4 className={styles.section__title}>Prospect Rewards</h4>
              {help.find(
                (h) => h.tag === 'define_campaign_prospect_rewards'
              ) && (
                <HelpModal
                  className={styles.helpModal}
                  help={help.find(
                    (h) => h.tag === 'define_campaign_prospect_rewards'
                  )}
                >
                  <p>
                    {
                      help.find(
                        (h) => h.tag === 'define_campaign_prospect_rewards'
                      )?.text
                    }
                  </p>
                </HelpModal>
              )}
            </div>
            {formik.values.prospectRewards.map((reward, rIndex) => (
              <ProspectReward
                className={styles.prospect_reward}
                disabled={activeCampagin ? false : isLocked}
                camp={activeCamp}
                reward={reward}
                rIndex={rIndex}
                error={
                  formik.touched.prospectRewards &&
                  formik.touched.prospectRewards[rIndex] &&
                  formik.errors.prospectRewards
                    ? formik.errors.prospectRewards[rIndex]
                    : {}
                }
                onClose={() =>
                  formik.setFieldValue(
                    'prospectRewards',
                    _.difference(formik.values.prospectRewards, [reward])
                  )
                }
                onChange={(reward) =>
                  formik.setFieldValue(
                    'prospectRewards',
                    formik.values.prospectRewards.map((_reward, index) =>
                      index === rIndex ? reward : _reward
                    )
                  )
                }
                key={rIndex}
                formik={formik}
              />
            ))}

            {/* {!isLocked && (
              <div className={styles.button__add_reward__wrapper}>
                <PrimaryButton
                  className={styles.button__add_reward}
                  onClick={() => formik.setFieldValue('prospectRewards', _.concat(formik.values.prospectRewards, REWARD__PR__ITEM))}
                >
                  <PlusCircleIcon className={styles.button__add_reward__icon} /> Add Reward
                </PrimaryButton>
              </div>
            )} */}
          </fieldset>

          <div className={styles.section__footer}>
            <SecondaryButton
              className={styles.button__back}
              label="Back"
              disabled={formik.values.status !== CAMPAIGN_STATUS.ACTIVE}
              onClick={onBack}
            />

            <PrimaryButton
              className={styles.button__next}
              label="Save"
              onClick={() => handleSubmitOnly()}
            />

            <PrimaryButton
              className={styles.button__next}
              label="Save & Next"
              onClick={() => handleClickSubmit(true)}
            />

            <SecondaryButton
              label="Save & Close"
              onClick={() => handleClickSubmit(false)}
            />
          </div>
        </form>
      </div>

      <LoadingOverlay isLoading={isLoading} />

      {/* <CampSeasonModal
        show={showSeasonModal}
        seasons={seasonOptions}
        onSave={seasons => initSeasonOptions(seasons)}
        onClose={() => setShowSeasonModal(false)}
      /> */}
    </>
  )
}

export default DefineCampaign
